body {
  margin: 0;
  font-family: 'futura-pt', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a, span {
  font-family: 'futura-pt', sans-serif;
  color: inherit;
}

html,
body,
#root,
.App {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  position: relative;
  overflow: hidden;
}

.App {
  display: flex;
  flex-direction: column;
}
